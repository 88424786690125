import React, { Component } from "react";

export default class SysAdminImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={945}
        height={583.22}
        viewBox="0 0 945 583.22"
      >
        <path
          d="M95.38 535.14a24.24 24.24 0 0023.39-4.14c8.19-6.88 10.76-18.2 12.84-28.68l6.18-31-12.93 8.91c-9.31 6.4-18.82 13-25.26 22.3s-9.26 21.94-4.08 32"
          fill="#e6e6e6"
        />
        <path
          d="M97.38 574.85C95.75 563 94.08 551 95.22 539c1-10.65 4.27-21.05 10.88-29.58A49.2 49.2 0 01118.73 498c1.26-.8 2.42 1.2 1.16 2a46.83 46.83 0 00-18.5 22.33c-4 10.24-4.68 21.41-4 32.3.42 6.58 1.31 13.12 2.2 19.65a1.19 1.19 0 01-.8 1.42 1.17 1.17 0 01-1.43-.8z"
          fill="#f2f2f2"
        />
        <path
          d="M109.1 555.81a17.84 17.84 0 0015.53 8c7.87-.37 14.42-5.86 20.32-11.07l17.45-15.41-11.55-.55c-8.3-.4-16.82-.77-24.73 1.79s-15.21 8.73-16.66 16.92"
          fill="#e6e6e6"
        />
        <path
          d="M92.78 581.67c7.84-13.87 16.93-29.29 33.18-34.22a37.21 37.21 0 0114-1.44c1.48.13 1.11 2.41-.37 2.28a34.39 34.39 0 00-22.27 5.9c-6.32 4.27-11.21 10.21-15.32 16.52-2.54 3.86-4.81 7.88-7.08 11.9-.75 1.28-2.87.39-2.14-.94z"
          fill="#f2f2f2"
        />
        <path
          d="M880.86 551.65a17.81 17.81 0 01-17.07 3.78c-7.51-2.37-12.44-9.35-16.81-15.89L834 520.18l11.31 2.42c8.13 1.74 16.47 3.55 23.46 8.06s12.47 12.32 11.78 20.61"
          fill="#e6e6e6"
        />
        <path
          d="M890 580.82c-4-15.41-8.88-32.64-23.33-41.56a37.12 37.12 0 00-13.13-5c-1.46-.25-1.69 2.05-.23 2.3a34.48 34.48 0 0120 11.39c5 5.74 8.19 12.74 10.58 19.89 1.46 4.38 2.63 8.85 3.8 13.31.43 1.47 2.72 1.11 2.31-.33z"
          fill="#f2f2f2"
        />
        <path
          d="M731.16 355.09H466.83a8 8 0 01-8-8V258a8 8 0 018-8h264.33a8 8 0 018 8v89.17a8 8 0 01-8 7.92z"
          fill="#f1f1f1"
        />
        <path fill="#444" d="M500.27 273.88H570.3299999999999V281.84H500.27z" />
        <circle cx={662.69} cy={278.66} r={4.78} fill="#444" />
        <circle cx={678.61} cy={278.66} r={4.78} fill="#444" />
        <circle cx={694.54} cy={278.66} r={4.78} fill="#444" />
        <path
          d="M731.16 468.15H466.83a8 8 0 01-8-8V371a8 8 0 018-8h264.33a8 8 0 018 8v89.17a8 8 0 01-8 7.98z"
          fill="#f1f1f1"
        />
        <path fill="#444" d="M500.27 386.94H570.3299999999999V394.9H500.27z" />
        <circle cx={662.69} cy={391.72} r={4.78} fill="#444" />
        <circle cx={678.61} cy={391.72} r={4.78} fill="#444" />
        <circle cx={694.54} cy={391.72} r={4.78} fill="#444" />
        <path
          d="M731.16 581.2H466.83a8 8 0 01-8-8v-89.13a8 8 0 018-8h264.33a8 8 0 018 8v89.17a8 8 0 01-8 7.96z"
          fill="#f1f1f1"
        />
        <path fill="#444" d="M500.27 499.99H570.3299999999999V507.95H500.27z" />
        <circle cx={662.69} cy={504.77} r={4.78} fill="#444" />
        <circle cx={678.61} cy={504.77} r={4.78} fill="#444" />
        <circle cx={694.54} cy={504.77} r={4.78} fill="#444" />
        <path
          d="M631.51 162.93l-.29-.22a3.57 3.57 0 00-5 .73l-59.5 80.11h-6.54V242a1.43 1.43 0 00-1.43-1.43h-3.91a1.43 1.43 0 00-1.44 1.43v1.54h-2.26V242a1.43 1.43 0 00-1.43-1.43h-3.9a1.44 1.44 0 00-1.44 1.43v1.54h-2.25V242a1.43 1.43 0 00-1.43-1.43h-3.9a1.43 1.43 0 00-1.43 1.43v1.54h-2.27V242a1.43 1.43 0 00-1.43-1.43h-3.9a1.43 1.43 0 00-1.43 1.43v1.54h-2.25V242a1.44 1.44 0 00-1.44-1.43h-3.9a1.43 1.43 0 00-1.43 1.43v1.54h-2.26V242a1.43 1.43 0 00-1.43-1.43h-3.91a1.43 1.43 0 00-1.43 1.43v1.54H506V242a1.43 1.43 0 00-1.43-1.43h-3.9a1.43 1.43 0 00-1.43 1.43v1.54H497V242a1.43 1.43 0 00-1.43-1.43h-3.9a1.43 1.43 0 00-1.43 1.43v1.54H488V242a1.43 1.43 0 00-1.43-1.43h-3.91a1.43 1.43 0 00-1.43 1.43v1.54H479V242a1.43 1.43 0 00-1.43-1.43h-3.91a1.43 1.43 0 00-1.43 1.43v1.54h-2.83a3.58 3.58 0 00-3.58 3.58v3.41a3.57 3.57 0 003.58 3.57h96.76a5.33 5.33 0 005.22-4.24l60.88-81.95a3.56 3.56 0 00-.75-4.98z"
          fill="#444"
        />
        <path
          fill="#ffb7b7"
          d="M255.5 550.83L267.52 556.98 296.97 513.54 279.23 504.47 255.5 550.83z"
        />
        <path
          d="M254.45 545.33l23.67 12.12a16.94 16.94 0 017.36 22.8l-.25.49-38.75-19.83z"
          fill="#444"
        />
        <path
          fill="#ffb7b7"
          d="M394.32 567.65L407.82 567.65 414.24 515.58 394.31 515.58 394.32 567.65z"
        />
        <path
          d="M390.87 563.24h26.59a17 17 0 0117 17v.55h-43.59zM331.16 142.87s-9-6.92-9 10l-1.43 55.91 15.95 52.82 9.27-17.13-3.8-37.12z"
          fill="#444"
        />
        <path d="M427.88 295.8s10.48 51.06-2.8 93l-7.34 161.11-26.91-2.1-9.43-119.56-9.09-59.41L353.44 423l-62.21 114.64-28.65-22.37s31.75-86.65 55.21-104.84l11.77-134z" />
        <circle
          cx={395.98}
          cy={43.93}
          r={30.68}
          transform="rotate(-61.34 395.959 43.936)"
          fill="#ffb7b7"
        />
        <path
          d="M393.81 50.43c3.68.48 6.45-3.28 7.74-6.76s2.27-7.53 5.46-9.41c4.37-2.56 10 .52 15-.32 5.64-1 9.31-6.94 9.6-12.66s-2-11.21-4.22-16.48l-.78 6.55A13 13 0 00420.89 0l1 9.61a10.21 10.21 0 00-11.69-8.45l.16 5.73c-6.51-.78-13.09-1.56-19.62-1s-13.12 2.65-18.08 7c-7.41 6.44-10.11 17-9.2 26.81s4.94 19 9.15 27.83c1.05 2.23 2.52 4.75 5 5 2.21.26 4.22-1.58 4.91-3.69a13.37 13.37 0 00-.06-6.59c-.62-3.29-1.4-6.66-.82-10s3-6.57 6.29-7 6.73 3.4 5.13 6.35z"
          fill="#444"
        />
        <path
          fill="#cbcbcb"
          d="M429.78 309.46L327.36 302.85 335.07 270.91 428.67 291.84 429.78 309.46z"
        />
        <path
          d="M366.61 85.77l6.39-8.92s7.18 2.44 26.41 12l1.36 8.36 33.37 205.3-60.67-2.62-16.47-.34-5.4-12.12-6.67 11.87-16.15-.3-16.41-9.51L328.55 240l5.23-45.21-8.09-42.59S315.51 113 354.72 92z"
          fill="#444"
        />
        <path
          d="M503.36 241.19a10.48 10.48 0 01-12.74-7.59 11 11 0 01-.3-1.82l-36-9.64 14.48-12.86 32 11.13a10.54 10.54 0 012.59 20.78z"
          fill="#ffb7b7"
        />
        <path
          d="M487.24 235.74a6.13 6.13 0 01-5 .4L423 213.66A63.22 63.22 0 01389 185l-24.69-44a19.76 19.76 0 1129.44-26.38l.05.06 42.74 67.74 53.78 31.53a6.16 6.16 0 012.62 6.55l-2.67 11.33a6.11 6.11 0 01-1.87 3.15 5.92 5.92 0 01-1.16.76z"
          fill="#444"
        />
        <path d="M944 583H1a1 1 0 010-2h943a1 1 0 010 2z" fill="#cbcbcb" />
      </svg>
    );
  }
}
